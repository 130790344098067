import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
import PageLayout from "layouts/PageLayout";
import * as routes from "routes";
import { CodeTokenList, FlexList, Icon, MentionList } from "ui";
import resume from "../../../data/resume.json";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = function AboutPage({
  children
}) {
  const description = <FlexList>
      <div>What data I cannot render, I do not understand.</div>
      <Icon as="a" href={resume.github} icon="github" size="s">
        Github
      </Icon>
      <Link to={routes.RESUME}>Resume</Link>
    </FlexList>;
  return <PageLayout description={description} title="About">
      {children}
    </PageLayout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2 {...{
      "id": "manifesto"
    }}><a parentName="h2" {...{
        "href": "#manifesto",
        "aria-label": "manifesto permalink",
        "className": "anchor"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manifesto`}</h2>
    <p>{`Information/data is all around us. I enjoy transforming data into simple delightful interfaces, empowering human workflows and experiences in an increasingly dehumanized (machinized) world.`}</p>
    <p>{`I wish to contribute towards an open and cheap future, where everyone can solve higher-order challenges without struggling with lower-order problems. I see the beauty in code as a way to engineer solutions and build an evolving platform for collaboration.`}</p>
    <p>{`I believe that the choices we make matter more than the goals we seek. They reflect our principles, beliefs and values on how we conduct ourselves and the work we create.`}</p>
    <h2 {...{
      "id": "interests"
    }}><a parentName="h2" {...{
        "href": "#interests",
        "aria-label": "interests permalink",
        "className": "anchor"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interests`}</h2>
    <p>{`Most of my projects touch on the following interest areas:`}</p>
    <CodeTokenList values={resume.interests} mdxType="CodeTokenList" />
    <h2 {...{
      "id": "tools"
    }}><a parentName="h2" {...{
        "href": "#tools",
        "aria-label": "tools permalink",
        "className": "anchor"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tools`}</h2>
    <p>{`As a hack-of-all-trades, I am unopinionated about tools and utilize what is best for the task. I have used the following tools effectively in past projects:`}</p>
    <CodeTokenList values={resume.skills} mdxType="CodeTokenList" />
    <h2 {...{
      "id": "influences"
    }}><a parentName="h2" {...{
        "href": "#influences",
        "aria-label": "influences permalink",
        "className": "anchor"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Influences`}</h2>
    <p>{`My approach of building simple, cheap and quality interfaces are largely inspired by the following people:`}</p>
    <MentionList mentions={resume.influences} mdxType="MentionList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      